import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const quotes = [
  "I've been pushing my Botox appointments back I really think it's be of this I used to get it every 3 months l'll be at 6 months and if things continue l'll keep pushing it out l'm so excited!",
  "My skin is looking better than ever bc of you!",
  "Some guy last night said he could tell i used tallow on my face bc my skin is so nice",
  "hook me up with a large body lotion please I am OBSESSED",
  "I think it feels really good. I've been using it at night with my face roller and it's a little bit hot here but this doesnt feel heavy or suffocating or greasy though it is staying on for hours. It's very soothing. I have sensitive skin as well.",
  "I'm obsessed with this lotion It's so soft and silky and smells amazing!! I love rose",
  "So this tallow is the only thing ive ever put on my face that has kept me moisturized through the entire night",
  "| got my lotion last night and it is divineeeee!!! Whipped yummminessss!!!! Skin felt so good this morning. It's incredible. Highly reccooo!!!!!",
];
const productImage = require("../assets/bg.png");

export const CoverScreen: React.FC = () => {
  const [currentQuote, setCurrentQuote] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#FFF5F0] to-[#F8F7FF]" />

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-14 md:pt-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h1 className="font-cabinet-grotesk text-[56px] leading-tight font-bold text-neutral-800">
              Feel the glow
            </h1>
            <p className="text-lg text-neutral-600 leading-relaxed max-w-xl">
              More than a natural, non-toxic moisturizer, but her face focuses
              on quality ingredients & sustainability. Crafted with love in
              Austin, TX with pasture-raised beef tallow from a small, local
              farmer.
            </p>

            {/* CTA Button */}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                         transition-all duration-300 hover:shadow-lg"
              onClick={() => navigate("/products")}
            >
              Pre-order Now
              <motion.span
                animate={{ x: [0, 4, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
                className="inline-block ml-2"
              >
                →
              </motion.span>
            </motion.button>
          </motion.div>

          {/* Right Column - Product Image */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative"
          >
            <motion.img
              src={productImage}
              alt="Product"
              className="w-full h-auto rounded-3xl"
              style={{ y: 0 }}
              whileInView={{
                y: [-10, 10],
                transition: {
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 2,
                },
              }}
            />
          </motion.div>
        </div>

        {/* Testimonials */}
        <div className="mt-24 relative">
          <h2 className="font-cabinet-grotesk text-3xl font-semibold text-neutral-800 mb-8 text-center">
            What our friends are saying
          </h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-6"
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={currentQuote}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="backdrop-blur-md bg-white/30 p-6 rounded-2xl"
              >
                <div className="flex flex-col h-full">
                  <p className="text-base italic text-neutral-700 flex-grow">
                    "{quotes[currentQuote]}"
                  </p>
                  <p className="mt-4 text-sm text-neutral-500">
                    — Verified Purchase
                  </p>
                </div>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>

        {/* Final CTA Section */}
        <div className="mt-20 mb-20 text-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                       transition-all duration-300 hover:shadow-lg"
            onClick={() => navigate("/products")}
          >
            Pre-order Now
            <motion.span
              animate={{ x: [0, 4, 0] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
              className="inline-block ml-2"
            >
              →
            </motion.span>
          </motion.button>
        </div>
      </div>
    </div>
  );
};
