import { auth } from "./auth";

export const API_BASE_URL = "https://api.butherfaceskincare.com/api";

export interface PreOrderData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  shippingAddress: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  items: Array<{ productId: number; quantity: number }>;
}

export interface Product {
  id: number;
  base_product_id: number;
  display_name: string;
  description: string;
  price: number;
  stock: number;
  size: string;
  base_name: string;
  base_description: string;
  ingredients: string;
  image_url: string;
}

export interface PreOrderItem {
  productId: number;
  product: {
    display_name: string;
    size?: string;
  };
  quantity: number;
  price_at_time: number;
}

export interface PreOrder {
  id: number;
  display_number?: string;
  status: string;
  created_at: string;
  orderDate?: string;
  user?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  userName?: string;
  email?: string;
  shippingAddress?: {
    street?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  items: PreOrderItem[];
  subtotal: number;
  total: number;
  discount_amount: number;
  commission_amount: number;
  discount_code_used?: string;
}

export interface UserDetails {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  shippingAddress: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

export interface DiscountCode {
  id: number;
  code: string;
  amount: number | null;
  percentage: number | null;
  commission_rate: number | null;
  is_one_time_use: boolean;
  max_uses: number | null;
  max_uses_per_user: number | null;
  valid_from: string;
  valid_until: string | null;
  is_active: boolean;
  current_uses: number;
  created_at: string;
  total_commission: number;
  total_discounts: number;
  total_sales: number;
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  created_at: string;
}

async function authenticatedFetch(url: string, options: RequestInit = {}) {
  if (auth.isAuthenticated()) {
    const token = auth.getToken();
    if (token) {
      try {
        const jwt = await auth.getJWT(token.token);
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${jwt}`,
        };
      } catch (error) {
        console.error("Error getting JWT:", error);
        auth.logout();
        throw new Error("Authentication failed. Please log in again.");
      }
    } else {
      auth.logout();
      throw new Error("No authentication token found. Please log in.");
    }
  }
  const response = await fetch(url, options);
  if (response.status === 403) {
    auth.logout();
    throw new Error("Invalid token. Please log in again.");
  }
  return response;
}

const handleApiError = async (error: any, response?: Response) => {
  console.error("API Error:", error);

  if (error.message === "Failed to fetch") {
    throw new Error(
      "Network error: Unable to connect to the server. Please check your internet connection and try again."
    );
  }

  if (response) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An unexpected error occurred");
    } else {
      throw new Error(
        `Server error: ${response.status} ${response.statusText}`
      );
    }
  }

  throw new Error("An unexpected error occurred. Please try again later.");
};

export const api = {
  async createPreOrder(data: PreOrderData) {
    const options: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    if (auth.isAuthenticated()) {
      const token = auth.getToken();
      if (token) {
        const jwt = await auth.getJWT(token.token);
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${jwt}`,
        };
      }
    }

    const response = await fetch(`${API_BASE_URL}/pre-order`, options);
    return response.json();
  },

  async getPreOrders() {
    const response = await authenticatedFetch(`${API_BASE_URL}/pre-orders`);
    return response.json();
  },

  async getProducts(): Promise<Product[]> {
    try {
      const response = await fetch(`${API_BASE_URL}/products`);
      if (!response.ok) {
        throw await handleApiError(
          new Error(`HTTP error! status: ${response.status}`),
          response
        );
      }
      console.log("products", response);

      return await response.json();
    } catch (error) {
      throw await handleApiError(error);
    }
  },

  async createProduct(data: Omit<Product, "id" | "base_product_id">) {
    const response = await authenticatedFetch(`${API_BASE_URL}/products`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  async login(email: string) {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    return response;
  },

  async getUserPreOrders(): Promise<PreOrder[]> {
    try {
      const response = await authenticatedFetch(
        `${API_BASE_URL}/user/pre-orders`
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error fetching pre-orders:", errorData);
        throw new Error(errorData.error || "Failed to fetch user pre-orders");
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        console.error("Unexpected data format:", data);
        throw new Error("Received invalid data format for pre-orders");
      }
      return data;
    } catch (error) {
      console.error("Error in getUserPreOrders:", error);
      throw error;
    }
  },

  async getUserDetails(): Promise<UserDetails | null> {
    if (!auth.isAuthenticated()) {
      return null;
    }
    try {
      const response = await authenticatedFetch(`${API_BASE_URL}/user/details`);
      if (!response.ok) {
        throw new Error("Failed to fetch user details");
      }
      return response.json();
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  },

  async getPreOrderById(id: number): Promise<PreOrder> {
    try {
      const response = await fetch(`${API_BASE_URL}/pre-order/${id}`);
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error fetching pre-order:", errorData);
        throw new Error(errorData.error || "Failed to fetch pre-order");
      }
      return response.json();
    } catch (error) {
      console.error("Error in getPreOrderById:", error);
      throw error;
    }
  },

  async getAllPreOrders(): Promise<PreOrder[]> {
    const response = await authenticatedFetch(
      `${API_BASE_URL}/admin/pre-orders`
    );
    return response.json();
  },

  async updatePreOrderStatus(
    orderId: number,
    newStatus: string
  ): Promise<void> {
    await authenticatedFetch(
      `${API_BASE_URL}/admin/pre-orders/${orderId}/status`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: newStatus }),
      }
    );
  },

  async validateDiscount(code: string, email: string) {
    try {
      const response = await fetch(`${API_BASE_URL}/validate-discount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, email }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || "Failed to validate discount code");
      }

      return response.json();
    } catch (error) {
      throw error;
    }
  },

  getDiscountCodes: async (): Promise<DiscountCode[]> => {
    const response = await authenticatedFetch(
      `${API_BASE_URL}/admin/discount-codes`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch discount codes");
    }
    return response.json();
  },

  createDiscountCode: async (data: {
    code: string;
    amount?: number;
    percentage?: number;
    isOneTimeUse: boolean;
    maxUses?: number;
    maxUsesPerUser?: number;
    commissionRate?: number;
    validFrom: string;
    validUntil?: string;
  }): Promise<DiscountCode> => {
    const response = await authenticatedFetch(
      `${API_BASE_URL}/admin/discount-codes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create discount code");
    }

    return response.json();
  },

  getUsers: async (): Promise<User[]> => {
    const response = await authenticatedFetch(`${API_BASE_URL}/admin/users`);
    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }
    return response.json();
  },

  updateUserRole: async (userId: number, role: string): Promise<void> => {
    const response = await authenticatedFetch(
      `${API_BASE_URL}/admin/users/${userId}/role`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ role }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update user role");
    }
  },
};
