import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../services/api";
import { CartSummary } from "./CartSummary";

interface ProductListProps {
  onAddToCart: (selections: { [key: string]: number }) => void;
  cartItems: { [key: string]: number };
  products: Product[];
}

interface GroupedProduct {
  base_name: string;
  description: string;
  ingredients: string;
  variants: Product[];
}

export const ProductList: React.FC<ProductListProps> = ({
  onAddToCart,
  cartItems,
  products,
}) => {
  const navigate = useNavigate();

  const [selectedVariants, setSelectedVariants] = useState<{
    [key: string]: number;
  }>({});

  // Ref and state for cart summary height
  const cartSummaryRef = useRef<HTMLDivElement>(null);
  const [cartSummaryHeight, setCartSummaryHeight] = useState(0);

  // Update cart summary height whenever cart changes (or when products load)
  useEffect(() => {
    if (cartSummaryRef.current) {
      setCartSummaryHeight(cartSummaryRef.current.offsetHeight);
    }
  }, [cartItems, products]);
  useEffect(() => {
    if (cartSummaryRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setCartSummaryHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(cartSummaryRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []); // Empty dependency array since we only want to set up the observer once
  // Group products and set initial selected variants
  const groupedProducts = useMemo(() => {
    const grouped = products.reduce(
      (acc: { [key: string]: GroupedProduct }, product) => {
        if (!acc[product.base_name]) {
          acc[product.base_name] = {
            base_name: product.base_name,
            description: product.description,
            ingredients: product.ingredients,
            variants: [],
          };
        }

        const isDefaultVariant = product.size === "4oz";

        if (isDefaultVariant) {
          setSelectedVariants((prev) => ({
            ...prev,
            [product.base_name]: product.id,
          }));
        }

        acc[product.base_name].variants.push(product);
        return acc;
      },
      {}
    );
    return grouped;
  }, [products]);

  const handleSizeChange = (baseName: string, variantId: number) => {
    setSelectedVariants((prev) => ({
      ...prev,
      [baseName]: variantId,
    }));
  };

  const handleQuantityChange = (productId: string, change: number) => {
    const newQuantity = Math.max(0, (cartItems[productId] || 0) + change);
    onAddToCart({ [productId]: newQuantity });
  };

  // Add this helper function near the top of your component
  const truncateText = (text: string, maxLength: number = 100) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength).trim() + "...";
  };

  return (
    <div className="relative min-h-screen">
      <div
        className="container mx-auto p-8  overflow-y-auto"
        style={{ marginBottom: cartSummaryHeight }} // Dynamically adjust bottom padding
      >
        <h1 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
          Our Products
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Object.values(groupedProducts).map((group) => {
            const selectedVariant =
              group.variants.find(
                (v) => v.id === selectedVariants[group.variants[0].base_name]
              ) || group.variants[0];

            return (
              <div
                key={group.variants[0].base_name}
                className="border border-neutral-200 rounded-lg p-6 shadow-sm flex flex-col h-full 
                           transition duration-300 ease-in-out hover:shadow-md hover:border-primary-300"
              >
                <div className="flex flex-col flex-grow">
                  {selectedVariant.image_url && (
                    <div
                      className="mb-4 cursor-pointer h-48 flex-shrink-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/products/${encodeURIComponent(group.base_name)}`
                        );
                      }}
                    >
                      <img
                        src={selectedVariant.image_url}
                        alt={selectedVariant.display_name}
                        className="w-full h-full object-cover rounded-lg hover:opacity-90 transition-opacity"
                      />
                    </div>
                  )}

                  <h2
                    className="font-cabinet-grotesk text-xl font-semibold text-neutral-800 mb-2 min-h-[3rem] line-clamp-2 
                           cursor-pointer hover:text-primary-600"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/products/${encodeURIComponent(group.base_name)}`
                      );
                    }}
                  >
                    {group.base_name}
                  </h2>

                  <p className="text-neutral-600 text-sm mb-4 flex-grow">
                    {truncateText(group.description)}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/products/${encodeURIComponent(group.base_name)}`
                        );
                      }}
                      className="ml-1 text-primary-600 hover:text-primary-700 hover:underline focus:outline-none"
                    >
                      Read more
                    </button>
                  </p>

                  <div className="mt-auto">
                    <div className="flex justify-between items-center mb-4">
                      <select
                        value={selectedVariant.id}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSizeChange(
                            selectedVariant.base_name,
                            Number(e.target.value)
                          );
                        }}
                        className="px-3 pr-8 py-2 border border-neutral-300 rounded-md text-neutral-600 
                                   focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:border-[#34324B]
                                   appearance-none bg-[url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E')] 
                                   bg-[length:0.7em] bg-[right_0.7em_center] bg-no-repeat"
                      >
                        {group.variants.map((variant) => (
                          <option key={variant.id} value={variant.id}>
                            {variant.size}
                          </option>
                        ))}
                      </select>
                      <span className="text-[#34324B] font-semibold text-lg">
                        ${Number(selectedVariant.price).toFixed(2)}
                      </span>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="text-lg font-semibold text-neutral-700">
                        {cartItems[selectedVariant.id] || 0} in cart
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleQuantityChange(
                            selectedVariant.id.toString(),
                            1
                          );
                        }}
                        className="px-4 py-2 bg-[#34324B] text-white rounded-md 
                                   hover:bg-[#2a2940] focus:outline-none 
                                   transition duration-200 ease-in-out"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <CartSummary
        ref={cartSummaryRef} // now this works
        cartItems={cartItems}
        products={products}
        onAddToCart={onAddToCart}
      />
    </div>
  );
};
