import React from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../services/api";

interface CartSummaryProps {
  cartItems: { [key: string]: number };
  products: Product[];
  onAddToCart: (cartItems: { [key: string]: number }) => void;
}

export const CartSummary = React.forwardRef<HTMLDivElement, CartSummaryProps>(
  ({ cartItems, products, onAddToCart }, ref) => {
    const navigate = useNavigate();

    const calculateTotal = () => {
      return products.reduce((total, product) => {
        return total + (cartItems[product.id] || 0) * Number(product.price);
      }, 0);
    };

    const hasItemsInCart = Object.values(cartItems).some(
      (quantity) => quantity > 0
    );

    return (
      <div
        ref={ref}
        className="fixed bottom-0 left-0 right-0 bg-white border-t border-neutral-200 p-4 shadow-md"
      >
        <div className="container mx-auto">
          <div className="mb-4 space-y-2">
            {products.map((product) => {
              const quantity = cartItems[product.id] || 0;
              if (quantity === 0) return null;

              return (
                <div
                  key={product.id}
                  className="flex justify-between items-center"
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={product.image_url}
                      alt={product.base_name}
                      className="w-10 h-10 object-cover rounded"
                    />
                    <span className="text-sm">
                      {product.base_name} ({product.size})
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() =>
                        onAddToCart({ [product.id]: quantity - 1 })
                      }
                      className="p-1 text-neutral-600 hover:text-[#34324B]"
                    >
                      -
                    </button>
                    <span className="w-8 text-center">{quantity}</span>
                    <button
                      onClick={() =>
                        onAddToCart({ [product.id]: quantity + 1 })
                      }
                      className="p-1 text-neutral-600 hover:text-[#34324B]"
                    >
                      +
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-neutral-600">
                Total Items:{" "}
                {Object.values(cartItems).reduce((a, b) => a + b, 0)}
              </p>
              <p className="font-cabinet-grotesk text-xl font-bold text-neutral-800">
                Total: ${calculateTotal().toFixed(2)}
              </p>
            </div>
            <button
              onClick={() => navigate("/pre-order")}
              className={`border-2 border-[#34324B] bg-white text-[#34324B] px-6 py-2.5 rounded-full text-base font-semibold 
                         focus:outline-none focus:ring-2 focus:ring-[#34324B] focus:ring-opacity-50 
                         transition duration-300 ease-in-out transform shadow-lg
                         ${
                           hasItemsInCart
                             ? "hover:bg-[#34324B] hover:text-white hover:-translate-y-1"
                             : "opacity-50 cursor-not-allowed"
                         }`}
              disabled={!hasItemsInCart}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    );
  }
);
