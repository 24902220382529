import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { CoverScreen } from "./CoverScreen";
import { ProductList } from "./ProductList";
import { PreOrderPage } from "./PreOrderPage";
import { LoginPage } from "./LoginPage";
import { VerifyToken } from "./VerifyToken";
import { PrivateRoute } from "./PrivateRoute";
import { UserPreOrders } from "./UserPreOrders";
import { auth } from "../services/auth";
import { OrderConfirmation } from "./OrderConfirmation";
import { AdminPage } from "./AdminPage";
import { AdminDashboard } from "./AdminDashboard";
import { AdminPreOrders } from "./AdminPreOrders";
import { AdminProducts } from "./AdminProducts";
import { Product, PreOrder } from "../services/api";
import { ProductPage } from "./ProductPage";
import { AdminDiscountCodes } from "./AdminDiscountCodes";
import { AdminUsers } from "./AdminUsers";

interface AppRoutesProps {
  cartItems: { [key: string]: number };
  onAddToCart: (selections: { [key: string]: number }) => void;
  products: Product[];
  preOrders: PreOrder[];
  user: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    details: {
      email: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  loading: boolean;
  error: string | null;
  dispatch: React.Dispatch<any>;
}

export const AppRoutes: React.FC<AppRoutesProps> = ({
  cartItems,
  onAddToCart,
  products,
  preOrders,
  user,
  loading,
  error,
  dispatch,
}) => {
  const navigate = useNavigate();

  // First useEffect for authentication and admin redirect
  useEffect(() => {
    const isAuthenticated = auth.isAuthenticated();
    const isAdmin = auth.isAdmin();

    dispatch({
      type: "SET_USER",
      payload: {
        isAuthenticated,
        isAdmin,
        details: user.details,
      },
    });

    if (!isAuthenticated && window.location.pathname.startsWith("/admin")) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Second useEffect for handling pre-orders
  useEffect(() => {
    const isAuthenticated = auth.isAuthenticated();
    const pendingPreOrder = localStorage.getItem("pendingPreOrder");

    if (pendingPreOrder && isAuthenticated) {
      try {
        const parsedPreOrder = JSON.parse(pendingPreOrder);
        const mergedItems = parsedPreOrder.items.reduce(
          (
            acc: { [key: string]: number },
            item: { productId: string; quantity: number }
          ) => {
            acc[item.productId] = item.quantity;
            return acc;
          },
          {}
        );
        onAddToCart(mergedItems);
        localStorage.removeItem("pendingPreOrder");
        navigate("/pre-order", { replace: true });
      } catch (error) {
        console.error("Error processing pre-order:", error);
        localStorage.removeItem("pendingPreOrder");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.details]);

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error)
    return (
      <div className="text-center mt-8">
        <h2 className="text-2xl font-bold text-red-600">Error</h2>
        <p className="mt-2">{error}</p>
        <p className="mt-4">
          If the problem persists, please try the following:
          <ul className="list-disc list-inside mt-2">
            <li>Check your internet connection</li>
            <li>Ensure the API server is running</li>
            <li>Clear your browser cache and reload the page</li>
            <li>Contact support if none of the above helps</li>
          </ul>
        </p>
      </div>
    );

  return (
    <Routes>
      <Route path="/" element={<CoverScreen />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/auth/verify" element={<VerifyToken />} />
      <Route
        path="/products"
        element={
          <ProductList
            onAddToCart={onAddToCart}
            cartItems={cartItems}
            products={products}
          />
        }
      />
      <Route
        path="/products/:baseProductName"
        element={
          <ProductPage
            products={products}
            onAddToCart={onAddToCart}
            cartItems={cartItems}
          />
        }
      />
      <Route
        path="/pre-order"
        element={
          <PreOrderPage cartItems={cartItems} onAddToCart={onAddToCart} />
        }
      />
      <Route
        path="/my-pre-orders"
        element={
          <PrivateRoute>
            <UserPreOrders dispatch={dispatch} preOrders={preOrders} />
          </PrivateRoute>
        }
      />
      <Route path="/order-confirmation" element={<OrderConfirmation />} />
      <Route
        path="/admin/*"
        element={
          <PrivateRoute adminOnly>
            <AdminPage />
          </PrivateRoute>
        }
      >
        <Route index element={<AdminDashboard />} />
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="pre-orders" element={<AdminPreOrders />} />
        <Route path="products" element={<AdminProducts />} />
        <Route path="discount-codes" element={<AdminDiscountCodes />} />
        <Route path="users" element={<AdminUsers />} />
      </Route>
    </Routes>
  );
};
