import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PreOrder } from "../services/api";
import { api } from "../services/api";

interface UserPreOrdersProps {
  preOrders: PreOrder[];
  dispatch: React.Dispatch<any>;
}

// Add this near the top, after interfaces
const ORDER_STATUSES = [
  "all",
  "pending",
  "processing",
  "shipped",
  "delivered",
  "cancelled",
];

// Add this helper function for status styling
const getStatusStyle = (status: string) => {
  const styles: Record<string, string> = {
    pending: "bg-yellow-100 text-yellow-800",
    processing: "bg-blue-100 text-blue-800",
    shipped: "bg-green-100 text-green-800",
    delivered: "bg-neutral-100 text-neutral-800",
    cancelled: "bg-red-100 text-red-800",
  };
  return styles[status.toLowerCase()] || "bg-neutral-100 text-neutral-800";
};

// Add this helper to get counts by status
const getStatusCounts = (orders: PreOrder[]) => {
  return orders.reduce((acc, order) => {
    const status = order.status.toLowerCase();
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
};

export const UserPreOrders: React.FC<UserPreOrdersProps> = ({
  preOrders = [],
  dispatch,
}) => {
  // Add state for selected filter
  const [selectedStatus, setSelectedStatus] = React.useState("all");

  useEffect(() => {
    const refreshPreOrders = async () => {
      try {
        const updatedPreOrders = await api.getUserPreOrders();
        dispatch({ type: "SET_PRE_ORDERS", payload: updatedPreOrders });
      } catch (error) {
        console.error("Error refreshing pre-orders:", error);
      }
    };
    refreshPreOrders();
  }, [dispatch]); // Refresh when component mounts

  // Add sorting to the filtered orders
  const filteredPreOrders = preOrders
    .filter((order) =>
      selectedStatus === "all"
        ? true
        : order.status.toLowerCase() === selectedStatus
    )
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

  const statusCounts = getStatusCounts(preOrders);

  const renderStatusLinks = () => {
    return Object.entries(statusCounts).map(([status, count], index, array) => (
      <React.Fragment key={status}>
        <button
          onClick={() => setSelectedStatus(status)}
          className="text-[#34324B] hover:underline font-medium"
        >
          {count} {status}
        </button>
        {index < array.length - 1 && (
          <span className="text-neutral-600">, </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
        Your Pre-Orders
      </h1>

      {/* Add the filter buttons */}
      <div className="flex flex-wrap gap-2 mb-8">
        {ORDER_STATUSES.map((status) => (
          <button
            key={status}
            onClick={() => setSelectedStatus(status)}
            className={`px-4 py-2 rounded-full text-sm font-medium capitalize transition-all duration-300
              ${
                status === selectedStatus
                  ? "bg-[#34324B] text-white"
                  : "bg-neutral-100 text-neutral-600 hover:bg-neutral-200"
              }`}
          >
            {status}
          </button>
        ))}
      </div>

      {preOrders.length === 0 ? (
        // Completely empty state - no orders at all
        <div className="text-center py-12">
          <p className="text-neutral-600 mb-8">You have no pre-orders yet.</p>
          <Link
            to="/products"
            className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                     transition-all duration-300 hover:shadow-lg inline-flex items-center"
          >
            Browse Products
            <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">
              →
            </span>
          </Link>
        </div>
      ) : filteredPreOrders.length === 0 ? (
        // Has orders, but none match the current filter
        <div className="text-center py-12">
          <p className="text-neutral-600">
            No {selectedStatus} orders found. You have {renderStatusLinks()}.
          </p>
        </div>
      ) : (
        <div className="space-y-6">
          {filteredPreOrders.map((order) => (
            <div
              key={order.id}
              className="bg-white shadow-md rounded-lg p-6 border border-neutral-100 hover:border-neutral-200 transition-all duration-300"
            >
              <div className="flex justify-between items-start mb-4">
                <h2 className="font-cabinet-grotesk text-xl font-semibold text-neutral-800">
                  Pre-Order #{order.display_number}
                </h2>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium capitalize
                               bg-neutral-100 text-neutral-700 ${getStatusStyle(
                                 order.status
                               )}`}
                >
                  {order.status}
                </span>
              </div>

              <p className="text-neutral-600 mb-4">
                Order Date: {new Date(order.created_at).toLocaleString()}
              </p>

              <div className="bg-neutral-50 rounded-lg p-4 mb-4">
                <h3 className="font-cabinet-grotesk text-lg font-medium mb-3 text-neutral-800">
                  Products
                </h3>
                <ul className="space-y-3">
                  {order.items.map((item, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <p className="text-neutral-800">
                          {item.product.display_name}
                        </p>
                        <p className="text-sm text-neutral-600">
                          Size: {item.product.size}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-neutral-800">Qty: {item.quantity}</p>
                        <p className="text-neutral-600">
                          ${Number(item.price_at_time).toFixed(2)}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="mt-4 pt-4 border-t border-neutral-200">
                  <div className="flex justify-between text-neutral-600">
                    <span>Subtotal:</span>
                    <span>${Number(order.subtotal).toFixed(2)}</span>
                  </div>
                  {order.discount_code_used && (
                    <div className="flex justify-between text-neutral-600">
                      <span>Discount ({order.discount_code_used}):</span>
                      <span>-${Number(order.discount_amount).toFixed(2)}</span>
                    </div>
                  )}
                  <div className="flex justify-between font-semibold text-neutral-800 mt-2">
                    <span>Total:</span>
                    <span>${Number(order.total).toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
