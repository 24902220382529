import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../services/auth";

export const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = auth.isAuthenticated();
  const isAdmin = auth.isAdmin();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  const handleLogout = () => {
    closeMenu();
    auth.logout();
    navigate("/login");
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          <Link
            to="/"
            className="text-2xl font-cabinet-grotesk font-bold text-neutral-800"
          >
            but her face
          </Link>

          {/* Hamburger button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  isMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>

          {/* Desktop menu */}
          <div className="hidden md:flex space-x-4">
            <Link
              to="/products"
              className="text-neutral-600 hover:text-[#34324B] transition duration-200"
            >
              Products
            </Link>

            {isAuthenticated ? (
              <>
                <Link
                  to="/my-pre-orders"
                  className="text-neutral-600 hover:text-[#34324B]"
                >
                  My Pre-Orders
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin/pre-orders"
                    className="text-neutral-600 hover:text-[#34324B]"
                  >
                    Admin Portal
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="text-neutral-600 hover:text-[#34324B] font-medium"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="text-neutral-600 hover:text-[#34324B] font-medium"
              >
                Login
              </Link>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMenuOpen ? "block" : "hidden"} pt-4`}>
          <div className="flex flex-col space-y-3">
            <Link
              to="/products"
              className="text-neutral-600 hover:text-[#34324B] transition duration-200"
              onClick={closeMenu}
            >
              Products
            </Link>

            {isAuthenticated ? (
              <>
                <Link
                  to="/my-pre-orders"
                  className="text-neutral-600 hover:text-[#34324B]"
                  onClick={closeMenu}
                >
                  My Pre-Orders
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin"
                    className="text-neutral-600 hover:text-[#34324B]"
                    onClick={closeMenu}
                  >
                    Admin Portal
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="text-neutral-600 hover:text-[#34324B] font-medium text-left"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="text-neutral-600 hover:text-[#34324B] font-medium"
                onClick={closeMenu}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
