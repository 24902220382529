import React, { useState, useEffect } from "react";
import { api, DiscountCode } from "../services/api";
import { auth } from "../services/auth";
import { useNavigate } from "react-router-dom";

export const AdminDiscountCodes: React.FC = () => {
  const [discountCodes, setDiscountCodes] = useState<DiscountCode[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();

  const [newCode, setNewCode] = useState({
    code: "",
    amount: "",
    percentage: "",
    commission_rate: "",
    isOneTimeUse: false,
    maxUses: "",
    maxUsesPerUser: "",
    validFrom: new Date().toISOString().split("T")[0],
    validUntil: "",
  });

  useEffect(() => {
    const fetchDiscountCodes = async () => {
      try {
        if (!auth.isAuthenticated() || !auth.isAdmin()) {
          setError("Unauthorized access");
          return;
        }

        const data = await api.getDiscountCodes();
        setDiscountCodes(data);
      } catch (err: any) {
        console.error("Error fetching discount codes:", err);
        if (err.message === "Invalid token. Please log in again.") {
          auth.logout();
          navigate("/login");
        } else {
          setError("Failed to fetch discount codes. Please try again later.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDiscountCodes();
  }, [navigate]);

  const handleCreateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!auth.isAuthenticated() || !auth.isAdmin()) {
        setError("Unauthorized access");
        return;
      }

      await api.createDiscountCode({
        code: newCode.code,
        amount: newCode.amount ? Number(newCode.amount) : undefined,
        percentage: newCode.percentage ? Number(newCode.percentage) : undefined,
        commissionRate: newCode.commission_rate
          ? Number(newCode.commission_rate)
          : undefined,
        isOneTimeUse: newCode.isOneTimeUse,
        maxUses: newCode.maxUses ? Number(newCode.maxUses) : undefined,
        maxUsesPerUser: newCode.maxUsesPerUser
          ? Number(newCode.maxUsesPerUser)
          : undefined,
        validFrom: newCode.validFrom,
        validUntil: newCode.validUntil || undefined,
      });

      setShowCreateModal(false);
      // Refresh the discount codes list
      const data = await api.getDiscountCodes();
      setDiscountCodes(data);
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to create discount code. Please try again later.");
    }
  };

  if (isLoading) {
    return <div className="text-center py-12">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
        <p className="text-neutral-800 mb-4">{error}</p>
        <button
          onClick={() => navigate("/admin")}
          className="text-[#34324B] hover:text-[#2a2840] underline"
        >
          Return to Admin Dashboard
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Discount Codes</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-[#34324B] text-white px-4 py-2 rounded-md hover:bg-[#2a2840]"
        >
          Create New Code
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-neutral-50">
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Code
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Customer Discount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Commission Rate
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Total Cost
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Usage
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Valid Period
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                Per User Limit
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-neutral-200">
            {discountCodes.map((code) => (
              <tr key={code.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-neutral-900">
                    {code.code}
                  </div>
                  <div className="text-sm text-neutral-500">
                    {code.is_one_time_use ? "One-time use" : "Multiple use"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    {code.percentage
                      ? `${code.percentage}%`
                      : code.amount
                      ? `$${code.amount}`
                      : "-"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    {code.commission_rate ? `${code.commission_rate}%` : "-"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    {code.percentage && code.commission_rate
                      ? `${
                          Number(code.percentage) + Number(code.commission_rate)
                        }% of sale`
                      : code.commission_rate
                      ? `$${code.amount} + ${code.commission_rate}% commission`
                      : code.percentage
                      ? `${code.percentage}% of sale`
                      : code.amount
                      ? `$${code.amount}`
                      : "-"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    <div>
                      Uses: {code.current_uses}
                      {code.max_uses ? ` / ${code.max_uses}` : ""}
                    </div>
                    <div>
                      Total Sales: ${Number(code.total_sales).toFixed(2)}
                    </div>
                    <div>
                      Total Discounts: $
                      {Number(code.total_discounts).toFixed(2)}
                    </div>
                    <div className="font-semibold text-orange-600">
                      Commission Owed: $
                      {Number(code.total_commission).toFixed(2)}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    {new Date(code.valid_from).toLocaleDateString()}
                    {code.valid_until
                      ? ` - ${new Date(code.valid_until).toLocaleDateString()}`
                      : " - No expiry"}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      code.is_active
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {code.is_active ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-neutral-900">
                    {code.max_uses_per_user || "No limit"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              Create New Discount Code
            </h3>
            <form onSubmit={handleCreateCode}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-neutral-700">
                    Code
                  </label>
                  <input
                    type="text"
                    value={newCode.code}
                    onChange={(e) =>
                      setNewCode({ ...newCode, code: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                    required
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-neutral-700">
                      Amount ($)
                    </label>
                    <input
                      type="number"
                      value={newCode.amount}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          amount: e.target.value,
                          percentage: "",
                        })
                      }
                      className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-neutral-700">
                      Percentage Discount (%)
                    </label>
                    <input
                      type="number"
                      value={newCode.percentage}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          percentage: e.target.value,
                          amount: "",
                        })
                      }
                      className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-neutral-700">
                      Commission Rate (%)
                    </label>
                    <input
                      type="number"
                      value={newCode.commission_rate}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          commission_rate: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-neutral-700">
                    Max Uses
                  </label>
                  <input
                    type="number"
                    value={newCode.maxUses}
                    onChange={(e) =>
                      setNewCode({ ...newCode, maxUses: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-neutral-700">
                    Max Uses Per User
                  </label>
                  <input
                    type="number"
                    value={newCode.maxUsesPerUser}
                    onChange={(e) =>
                      setNewCode({ ...newCode, maxUsesPerUser: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                  />
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={newCode.isOneTimeUse}
                    onChange={(e) =>
                      setNewCode({ ...newCode, isOneTimeUse: e.target.checked })
                    }
                    className="h-4 w-4 text-[#34324B] focus:ring-[#34324B] border-neutral-300 rounded"
                  />
                  <label className="ml-2 block text-sm text-neutral-700">
                    One-time use
                  </label>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-neutral-700">
                      Valid From
                    </label>
                    <input
                      type="date"
                      value={newCode.validFrom}
                      onChange={(e) =>
                        setNewCode({ ...newCode, validFrom: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-neutral-700">
                      Valid Until
                    </label>
                    <input
                      type="date"
                      value={newCode.validUntil}
                      onChange={(e) =>
                        setNewCode({ ...newCode, validUntil: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-[#34324B] focus:ring-[#34324B]"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 border border-neutral-300 rounded-md text-sm font-medium text-neutral-700 hover:bg-neutral-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#34324B] text-white rounded-md text-sm font-medium hover:bg-[#2a2840]"
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
