import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { api, PreOrder } from "../services/api";
import getStatusStyle from "../utils/orderStyles";

export const OrderConfirmation: React.FC = () => {
  const [order, setOrder] = useState<PreOrder | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetchOrder = async () => {
      const state = location.state as { orderId: number };
      console.log("state", state);
      if (!state || !state.orderId) {
        setError("No order ID provided");
        setLoading(false);
        return;
      }

      try {
        const orderData = await api.getPreOrderById(state.orderId);
        setOrder(orderData);
      } catch (err) {
        console.error("Error fetching order:", err);
        setError("Failed to fetch order details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [location.state]);

  if (loading)
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center py-12">
          <p className="text-neutral-600">Loading order details...</p>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center py-12">
          <p className="text-neutral-600">{error}</p>
          <Link
            to="/products"
            className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                   transition-all duration-300 hover:shadow-lg inline-flex items-center mt-8"
          >
            Browse Products
            <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">
              →
            </span>
          </Link>
        </div>
      </div>
    );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
        Order Confirmation
      </h1>

      {order ? (
        <div className="bg-white shadow-md rounded-lg p-6 border border-neutral-100">
          <div className="flex justify-between items-start mb-4">
            <h2 className="font-cabinet-grotesk text-xl font-semibold text-neutral-800">
              Pre-Order #{order.display_number}
            </h2>
            <span
              className={`px-3 py-1 rounded-full text-sm font-medium capitalize
              ${getStatusStyle(order.status)}`}
            >
              {order.status}
            </span>
          </div>

          <p className="text-neutral-600 mb-4">
            Order Date: {new Date(order.created_at).toLocaleString()}
          </p>

          <div className="bg-neutral-50 rounded-lg p-4">
            <h3 className="font-cabinet-grotesk text-lg font-medium mb-3 text-neutral-800">
              Products
            </h3>
            <ul className="space-y-3">
              {order.items.map((item, index) => (
                <li key={index} className="flex justify-between items-center">
                  <div>
                    <p className="text-neutral-800">
                      {item.product.display_name}
                    </p>
                    <p className="text-sm text-neutral-600">
                      Size: {item.product.size}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-neutral-800">Qty: {item.quantity}</p>
                    <p className="text-neutral-600">
                      ${Number(item.price_at_time).toFixed(2)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>

            <div className="mt-4 pt-4 border-t border-neutral-200">
              <div className="flex justify-between text-neutral-600">
                <span>Subtotal:</span>
                <span>${Number(order.subtotal).toFixed(2)}</span>
              </div>
              {order.discount_code_used && (
                <div className="flex justify-between text-neutral-600">
                  <span>Discount ({order.discount_code_used}):</span>
                  <span>-${Number(order.discount_amount).toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between font-semibold text-neutral-800 mt-2">
                <span>Total:</span>
                <span>${Number(order.total).toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <Link
              to="/products"
              className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                       transition-all duration-300 hover:shadow-lg inline-flex items-center"
            >
              Continue Shopping
              <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">
                →
              </span>
            </Link>
          </div>
        </div>
      ) : (
        <div className="text-center py-12">
          <p className="text-neutral-600 mb-8">No order details available.</p>
          <Link
            to="/products"
            className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                     transition-all duration-300 hover:shadow-lg inline-flex items-center"
          >
            Browse Products
            <span className="inline-block ml-2 transition-transform group-hover:translate-x-1">
              →
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};
