import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, Product, UserDetails } from "../services/api";
import { Modal } from "./Modal"; // We'll create this component
import { motion } from "framer-motion";
import { auth } from "../services/auth";

interface PreOrderPageProps {
  cartItems: { [key: string]: number };
  onAddToCart: (selections: { [key: string]: number }) => void;
}

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  termsAgreed?: string;
}

interface DiscountCode {
  id: number;
  code: string;
  amount?: number;
  percentage?: number;
}

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  shippingAddress: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  items: { productId: number; quantity: number }[];
  discountCode?: string;
}

export const PreOrderPage: React.FC<PreOrderPageProps> = ({
  cartItems,
  onAddToCart,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<FormData>({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    shippingAddress: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "United States", // Hardcoded country
    },
    items: [],
  });
  const navigate = useNavigate();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [appliedDiscount, setAppliedDiscount] = useState<DiscountCode | null>(
    null
  );
  const [discountError, setDiscountError] = useState<string | null>(null);
  const [applyingDiscount, setApplyingDiscount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsData, userDetails] = await Promise.all([
          api.getProducts(),
          api.getUserDetails(),
        ]);
        setProducts(productsData);
        if (userDetails) {
          prefillUserDetails(userDetails);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      items: Object.entries(cartItems).map(([productId, quantity]) => ({
        productId: parseInt(productId),
        quantity,
      })),
    }));
  }, [cartItems]);

  useEffect(() => {
    const hasItems = Object.values(cartItems).some((qty) => qty > 0);
    if (!hasItems) {
      navigate("/products");
    }
  }, [cartItems, navigate]);

  const prefillUserDetails = (userDetails: UserDetails) => {
    setFormData((prev) => ({
      ...prev,
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phoneNumber: userDetails.phoneNumber || "",
      shippingAddress: {
        ...userDetails.shippingAddress,
        country: "United States", // Ensure country is always set to United States
      },
    }));
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (!formData.email) errors.email = "Email is required";
    if (!formData.firstName) errors.firstName = "First name is required";
    if (!formData.lastName) errors.lastName = "Last name is required";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
    if (!formData.shippingAddress.street) errors.street = "Street is required";
    if (!formData.shippingAddress.city) errors.city = "City is required";
    if (!formData.shippingAddress.state) errors.state = "State is required";
    if (!formData.shippingAddress.postalCode)
      errors.postalCode = "Postal code is required";

    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await api.createPreOrder(formData);
      if (response.error === "Email already exists") {
        const wantsToLogin = window.confirm(
          "This email is already registered. Would you like us to send you a login link to complete your pre-order?"
        );

        if (wantsToLogin) {
          try {
            await auth.login(formData.email);
            localStorage.setItem("pendingPreOrder", JSON.stringify(formData));
            alert(
              "We've sent a login link to your email. Please check your inbox and click the link to complete your pre-order."
            );
          } catch (loginError) {
            console.error("Error sending login email:", loginError);
            setFormErrors({
              ...formErrors,
              email: "Failed to send login email. Please try again.",
            });
          }
        } else {
          setFormErrors({
            ...formErrors,
            email: "Please use a different email address or login to continue.",
          });
        }
      } else {
        navigate("/order-confirmation", {
          state: { orderId: response.orderId?.id },
        });
      }
    } catch (error) {
      console.error("Error creating pre-order:", error);
      alert(
        "An error occurred while creating your pre-order. Please try again."
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear the error when the user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        [name]: value,
      },
    }));
    // Clear the error when the user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const calculateTotal = () => {
    const subtotal = products.reduce((total, product) => {
      const quantity = cartItems[product.id.toString()] || 0;
      return total + product.price * quantity;
    }, 0);

    if (!appliedDiscount) return subtotal;

    if (appliedDiscount.percentage) {
      const discount = subtotal * (appliedDiscount.percentage / 100);
      return subtotal - discount;
    }

    if (appliedDiscount.amount) {
      return Math.max(0, subtotal - appliedDiscount.amount);
    }

    return subtotal;
  };

  const handleApplyDiscount = async () => {
    if (!discountCode.trim()) return;

    setApplyingDiscount(true);
    setDiscountError(null);

    try {
      const response = await api.validateDiscount(discountCode, formData.email);
      setAppliedDiscount(response);
      setFormData((prev) => ({
        ...prev,
        discountCode: discountCode,
      }));
    } catch (error) {
      setDiscountError(
        error instanceof Error ? error.message : "Failed to apply discount code"
      );
      setAppliedDiscount(null);
    } finally {
      setApplyingDiscount(false);
    }
  };

  const handleQuantityChange = (productId: string, newQuantity: number) => {
    onAddToCart({
      ...cartItems,
      [productId]: newQuantity,
    });

    setFormData((prev) => ({
      ...prev,
      items: prev.items.map((item) =>
        item.productId === parseInt(productId)
          ? { ...item, quantity: newQuantity }
          : item
      ),
    }));
  };

  const renderOrderSummary = () => {
    const subtotal = products.reduce((total, product) => {
      const quantity = cartItems[product.id.toString()] || 0;
      return total + product.price * quantity;
    }, 0);

    return (
      <div className="bg-neutral-50 p-4 sm:p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
        <div className="space-y-4">
          {products.map((product) => {
            const quantity = cartItems[product.id.toString()] || 0;
            if (quantity === 0) return null;

            return (
              <div
                key={product.id}
                className="flex flex-col sm:flex-row sm:items-center gap-3 pb-3 border-b border-neutral-200"
              >
                {/* Product Info */}
                <div className="flex items-center gap-3 flex-grow">
                  <img
                    src={product.image_url}
                    alt={product.base_name}
                    className="w-16 h-16 object-cover rounded-md"
                  />
                  <div className="min-w-0 flex-grow">
                    <p className="text-neutral-800 font-medium truncate">
                      {product.base_name}
                    </p>
                    <p className="text-sm text-neutral-600">{product.size}</p>
                    <p className="text-neutral-800 font-medium sm:hidden">
                      ${(product.price * quantity).toFixed(2)}
                    </p>
                  </div>
                </div>

                {/* Quantity Controls & Price */}
                <div className="flex items-center justify-between sm:justify-end gap-4 mt-2 sm:mt-0">
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      onClick={() =>
                        handleQuantityChange(
                          product.id.toString(),
                          quantity - 1
                        )
                      }
                      className="p-2 text-neutral-600 hover:text-[#34324B] border rounded"
                    >
                      -
                    </button>
                    <span className="w-8 text-center">{quantity}</span>
                    <button
                      type="button"
                      onClick={() =>
                        handleQuantityChange(
                          product.id.toString(),
                          quantity + 1
                        )
                      }
                      className="p-2 text-neutral-600 hover:text-[#34324B] border rounded"
                    >
                      +
                    </button>
                  </div>
                  <p className="hidden sm:block text-neutral-800 font-medium w-20 text-right">
                    ${(product.price * quantity).toFixed(2)}
                  </p>
                </div>
              </div>
            );
          })}

          {/* Discount Code Section */}
          <div className="pt-4">
            <div className="flex flex-col sm:flex-row gap-2">
              <input
                type="text"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
                placeholder="Discount Code"
                className="px-3 py-2 border rounded-md flex-grow"
                disabled={!!appliedDiscount}
              />
              <button
                onClick={handleApplyDiscount}
                disabled={
                  applyingDiscount || !discountCode || !!appliedDiscount
                }
                className={`px-4 py-2 rounded-md whitespace-nowrap ${
                  appliedDiscount
                    ? "bg-green-500 text-white"
                    : "bg-neutral-800 text-white hover:bg-neutral-700"
                } disabled:opacity-50`}
              >
                {applyingDiscount
                  ? "Applying..."
                  : appliedDiscount
                  ? "Applied"
                  : "Apply"}
              </button>
            </div>
            {discountError && (
              <p className="text-red-500 text-sm mt-1">{discountError}</p>
            )}
            {appliedDiscount && (
              <p className="text-green-600 text-sm mt-1">
                Discount applied:{" "}
                {appliedDiscount.percentage
                  ? `${appliedDiscount.percentage}% off`
                  : `$${appliedDiscount.amount?.toFixed(2)} off`}
              </p>
            )}
          </div>

          {/* Totals Section */}
          <div className="pt-3 space-y-2">
            <div className="flex justify-between items-center">
              <span>Subtotal</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
            {appliedDiscount && (
              <div className="flex justify-between items-center text-green-600">
                <span>Discount</span>
                <span>-${(subtotal - calculateTotal()).toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between items-center font-semibold text-lg pt-2 border-t border-neutral-200">
              <span>Total</span>
              <span>${calculateTotal().toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Pre-Order</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        {renderOrderSummary()}

        <div>
          <label htmlFor="email" className="block mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className={`w-full px-3 py-2 border rounded-md ${
              formErrors.email ? "border-red-500" : ""
            }`}
          />
          {formErrors.email && (
            <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
          )}
        </div>

        <div>
          <label htmlFor="phoneNumber" className="block mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="(123) 456-7890"
            className={`w-full px-3 py-2 border rounded-md ${
              formErrors.phoneNumber ? "border-red-500" : ""
            }`}
          />
          {formErrors.phoneNumber && (
            <p className="text-red-500 text-sm mt-1">
              {formErrors.phoneNumber}
            </p>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName" className="block mb-1">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${
                formErrors.firstName ? "border-red-500" : ""
              }`}
            />
            {formErrors.firstName && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.firstName}
              </p>
            )}
          </div>
          <div>
            <label htmlFor="lastName" className="block mb-1">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              className={`w-full px-3 py-2 border rounded-md ${
                formErrors.lastName ? "border-red-500" : ""
              }`}
            />
            {formErrors.lastName && (
              <p className="text-red-500 text-sm mt-1">{formErrors.lastName}</p>
            )}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Shipping Address</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="street" className="block mb-1">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                value={formData.shippingAddress.street}
                onChange={handleAddressChange}
                required
                className={`w-full px-3 py-2 border rounded-md ${
                  formErrors.street ? "border-red-500" : ""
                }`}
              />
              {formErrors.street && (
                <p className="text-red-500 text-sm mt-1">{formErrors.street}</p>
              )}
            </div>
            <div>
              <label htmlFor="city" className="block mb-1">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.shippingAddress.city}
                onChange={handleAddressChange}
                required
                className={`w-full px-3 py-2 border rounded-md ${
                  formErrors.city ? "border-red-500" : ""
                }`}
              />
              {formErrors.city && (
                <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
              )}
            </div>
            <div>
              <label htmlFor="state" className="block mb-1">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.shippingAddress.state}
                onChange={handleAddressChange}
                required
                className={`w-full px-3 py-2 border rounded-md ${
                  formErrors.state ? "border-red-500" : ""
                }`}
              />
              {formErrors.state && (
                <p className="text-red-500 text-sm mt-1">{formErrors.state}</p>
              )}
            </div>
            <div>
              <label htmlFor="postalCode" className="block mb-1">
                Postal Code
              </label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formData.shippingAddress.postalCode}
                onChange={handleAddressChange}
                required
                className={`w-full px-3 py-2 border rounded-md ${
                  formErrors.postalCode ? "border-red-500" : ""
                }`}
              />
              {formErrors.postalCode && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.postalCode}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="text-sm text-neutral-600 mb-4">
          By placing this pre-order, you agree to our{" "}
          <button
            type="button"
            onClick={() => setShowTermsModal(true)}
            className="text-blue-500 hover:underline"
          >
            Terms of Use
          </button>{" "}
          and{" "}
          <button
            type="button"
            onClick={() => setShowPrivacyModal(true)}
            className="text-blue-500 hover:underline"
          >
            Privacy Policy
          </button>
        </div>

        <motion.button
          type="submit"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="group px-8 py-4 bg-[#34324B] text-white rounded-full 
                     transition-all duration-300 hover:shadow-lg"
        >
          Place Pre-Order
          <motion.span
            animate={{ x: [0, 4, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
            className="inline-block ml-2"
          >
            →
          </motion.span>
        </motion.button>
      </form>

      <Modal
        isOpen={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        title="Terms of Use"
      >
        <p>
          {" "}
          Terms of Use. Acceptance of Terms By using the “But Her Face”
          pre-order platform, you agree to comply with and be bound by the
          following Terms of Use. If you do not agree with these terms, please
          refrain from using our services. 2. Pre-Orders The platform allows
          customers to submit pre-orders for skincare products. Payment for
          pre-orders may be completed upon confirmation, as per communication
          with the “But Her Face” team. Pre-orders do not guarantee immediate
          fulfillment and may be subject to product availability. 3. Product
          Information We strive to ensure that product descriptions and other
          information on our website are accurate. However, we cannot guarantee
          that details are free from errors. We reserve the right to correct any
          inaccuracies, update product descriptions, and modify prices at our
          discretion. 4. User Accounts Users may log in using a magic link sent
          to their registered email address. You are responsible for maintaining
          the confidentiality of your account details and for any activity under
          your account. 5. Communication Consent By providing your email address
          and phone number and opting in, you consent to receive updates
          regarding new product batches, promotions, and other marketing
          communications via email and SMS. You may unsubscribe from emails by
          following the unsubscribe link in any email or contacting us directly.
          To stop receiving SMS messages, reply “STOP” to any message. 6.
          Limitation of Liability “But Her Face” is not liable for any damages
          arising from the use or inability to use the pre-order platform,
          including any reliance on the information provided on the website. 7.
          Changes to Terms We may revise these Terms of Use at any time. By
          continuing to use our platform, you agree to any updated terms. 8.
          Contact For questions about these Terms of Use, please contact us at
          info@butherfaceskincare.com.
        </p>
      </Modal>

      <Modal
        isOpen={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
        title="Privacy Policy"
      >
        <p>
          Privacy Policy 1. Information Collection We collect personal
          information that you voluntarily provide, such as your name, email
          address, phone number, shipping address, and order preferences, to
          process your pre-orders and communicate with you. 2. Use of
          Information We use the information we collect to: • Process and
          fulfill your pre-orders. • Provide you with updates on product
          batches, promotions, and other marketing communications via email and
          SMS if you have consented to such communications. • Improve our
          services and website. 3. Communication Consent If you opt in, we may
          send you marketing emails and SMS messages about new products,
          upcoming batches, and promotions. You can withdraw your consent at any
          time by following the unsubscribe link in our emails or by replying
          “STOP” to SMS messages. Alternatively, you may contact us directly to
          manage your communication preferences. 4. Information Sharing We do
          not sell or share your personal information with third parties except
          to fulfill orders, comply with legal obligations, or protect our
          rights. Any third parties we work with (e.g., delivery services) are
          bound by similar data protection obligations. 5. Data Security We
          implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, or loss. However, no
          method of transmission over the internet or electronic storage is 100%
          secure, so we cannot guarantee absolute security. 6. Data Retention We
          retain personal information for as long as necessary to fulfill the
          purposes described in this policy or as required by law. 7. User
          Rights Depending on your jurisdiction, you may have rights regarding
          your personal information, such as access, correction, or deletion.
          Please contact us if you wish to exercise these rights. 8. Changes to
          Privacy Policy We may update this Privacy Policy periodically. Any
          changes will be posted on this page, and we encourage you to review it
          regularly. 9. Contact For questions regarding our Privacy Policy or
          your personal information, please reach out to us at
          info@butherfaceskincare.com.
        </p>
      </Modal>
    </div>
  );
};
