import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

export const AdminPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("pre-orders");

  const tabs = [
    { id: "pre-orders", label: "Pre-Orders" },
    { id: "discount-codes", label: "Discount Codes" },
    { id: "users", label: "Users" },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="font-cabinet-grotesk text-4xl font-bold mb-8 text-neutral-800">
        Admin Portal
      </h1>
      <div className="flex flex-wrap gap-2 mb-8">
        {tabs.map((tab) => (
          <Link
            key={tab.id}
            to={`/admin/${tab.id}`}
            className={`px-4 py-2 rounded-full text-sm font-medium capitalize transition-all duration-300
              ${
                activeTab === tab.id
                  ? "bg-[#34324B] text-white"
                  : "bg-neutral-100 text-neutral-600 hover:bg-neutral-200"
              }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </Link>
        ))}
      </div>
      <Outlet />
    </div>
  );
};
